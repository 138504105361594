import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'

const ProjectTeaser = (props) => {
  return (
    <div className={`flex flex-wrap w-full items-center ${props.className} ${props.rtl ? 'flex-row-reverse' : 'flex-row'}`}>
      <div className='w-full lg:w-8/12 h-580'>
        <img src={props.img} alt={props.title} className='fit-image' />
      </div>
      <div className='flex flex-col w-full lg:w-4/12 p-12'>
        <span className='font-headline mb-4 uppercase'>{props.smallTitle}</span>
        <h3 className='font-element text-3xl mb-12 md:mb-16'>{props.title}</h3>
        <Button to={`/projekte/${props.slug}`} withArrow className='font-bold font-headline'>
          mehr zum Projekt
        </Button>
      </div>
    </div>
  )
}

ProjectTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
}

ProjectTeaser.defaultProps = {
  rtl: false
}

export default ProjectTeaser
